<template>
  <footer>
    <div class="wrapper clearfix">
      <div class="left">
        <img :src="commonInfo.logo" alt="logo" />
        <p>{{$t('v.foot.tips')}}</p>
        <div class="follow-us">
          <i v-for="item in iList" :key="item.index" :class="item.icon" class="iconfont"></i>
        </div>
        <p>copyright © 2011-2019</p>
      </div>
      <div class="right">
        <div class="list" v-show="aboutList.length">
          <p class="title">{{commonInfo.title}}</p>
          <ul>
            <li v-for="item in aboutList" :key="item.index">
              <a :href="$t(item.redirectUrl)" target="_blank">{{$t(item.name)}}</a>
            </li>
          </ul>
        </div>
        <div class="list" v-show="proList.length">
          <p class="title">{{$t('v.foot.support')}}</p>
          <ul>
            <li v-for="item in proList" :key="item.index">
              <a :href="$t(item.redirectUrl)" target="_blank">{{$t(item.name)}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'CustomFooter',
  data() {
    return {
      aboutList: [],
      proList: [],
      blogroll: {},
      iList: [
        {
          icon: 'icon-weibo'
        },
        {
          icon: 'icon-wechat'
        },
        {
          icon: 'icon-facebook'
        },
        {
          icon: 'icon-twitter'
        }
      ],
      pList: [
        {
          icon: 'icon5'
        },
        {
          icon: 'icon6'
        }
      ]
    }
  },
  computed: {
    commonInfo() {
      return this.$store.state.__commonInfo
    }
  },
  methods: {
    register() {
      window.location.href = this.commonInfo.register
    },
    getBlogroll() {
      this.$ApiClient.blogroll().then(res => {
        if (res.code === 200) {
          this.blogroll = res.data
        }
      })
    },
    getArticleData(lang) {
      this.proList = []
      this.$ApiClient.article().then(res => {
        if (res.code === 200) {
          if (lang === 'zh-CN') {
            res.data.forEach(el => {
              if (el.sysLanguage === 'CN') {
                this.proList.push(el)
              }
            })
          } else {
            res.data.forEach(el => {
              if (el.sysLanguage === 'EN') {
                this.proList.push(el)
              }
            })
          }
          this.aboutList = this.proList.slice(0, 5)
          this.proList.splice(0, 5)
        }
      })
    }
  },
  created() {
    this.getBlogroll()
    this.getArticleData(window.localStorage.lang || 'zh-CN')
    this.$EventBus.on('_languageSwitched', this.getArticleData)
  }
}
</script>

<style lang="less" scoped>
@import './custom-footer';
</style>
