module.exports = {
  commonheader: {
    home: 'página inicial',
    market: 'mercado',
  },
  commonfooter: {
    copyright: 'copyright',
    plat: 'plataforma',
    download: 'baixar',
  },
  indexPage: {
    title: ' o mais confiável ao seu redor',
    register: 'cadastrar',
    registerPlaceholder: 'inserir seu email ou telefone ',
    tradeSystem: {
      spot: {
        name: 'negociação em moeda digital',
        title: 'troca de ativos digitais em todo tipo de moeda',
        t1: 'unificação de alto desempenho de memória pura',
        t2: 'centenas pares de negociação',
        t3: 'baixo custo de transação',
        t4: 'excelente liquidez',
      },
      otc: {
        name: 'negociação em moeda legal',
        title: 'suporta câmbio com moeda legal',
        t1: 'sem cobrança de taxas',
        t2: 'canal de apelação bidirecional, garantindo os direitos de ambos os partes',
        t3: 'auto escolha + transação rápida , modo duplo e direção múltiplo',
        t4: 'alta segurança com sistema de depósito do comerciante',
      },
      cfd: {
        name: 'negociação por contrato',
        title: 'o design do contrato mais simples e humano',
        t1: 'mecanismo de proteção das penetrantes, reduz os riscos de transação',
        t2: 'sem data de entrega , e divide perda',
        t3: '',
        t4: 'marque preço e negocie',
        t5: 'evitar operações maliciosos para o mercado',
      }
    },
    product: {
      title: 'produto e serviço',
      desc: 'fornece serviços de negociação e gerenciamento de ativos digitais seguros e confiáveis para milhões de usuários em mundo inteiro, ajude a economia da blockchain a evoluir e reformule o novo padrão de ecology do setor',
      t1: 'layout global',
      p1: 'estabeleceram em vários países centro de serviços de transação criando múltiplos serviço em união',
      t2: 'sistema de segurança ',
      p2: 'sistema de forma distribuída e de anti ataque DOS profissional , separação de carteira quente e fria, proteção multidimensional dos ativos do usuário',
      t3: 'experiência de negociação fluente',
      p3: 'uso de mecanismo de transação com eficiência par garantir uma experiência de transação fluente, pode processar milhões de pedidos por segundo e oferecer suporte à operação estável com mais de 20 milhões de usuários online simultaneamente',
      t4: 'proteção com equipe profissional',
      p4: 'o desenvolvimento de um equipe de especialistas no setor financeiro foi concluído e todos os detalhes foram cuidadosamente criados para garantir uma operação segura, estável e eficiente da plataforma',
    },
    download: {
      title: 'transação a qualquer hora e em qualquer lugar',
      desc: 'fornece uma página clara, com pedido emm tempo red, ferramentas de desenho etc., suporta serviços completos para várias plataformas como IOS, Android e Mac',
      btn: 'baixar agora',
    },
    price: {
      th1: 'par de negociação',
      th2: 'preço mais recente',
      th3: 'aumento em 24h',
      th4: 'maior em 24h',
      th5: 'menor em 24h',
      th6: 'quantidade negociado em 24h',
      th7: 'operação',
      buy: 'comprar',
    }
  },
  service: {
    USDT: 'USDT',
    BTC: 'BTC',
    ETH: 'ETH',
    CUSTOM: 'auto escolha ',
    COIN: 'moedas',
    NewPrice: 'preço mais recente',
    ExchangeNum: 'quantidade em 24h',
    Change: 'mudança em 24h',
    high: 'maior em 24h',
    low: 'menor em 24h',
    OpenPrice: 'preço de abertura',
    PriceTrend: 'tendência de preço',
    Operate: 'operação',
    Exchange: 'negociação ',
  },
  register: {
    come: 'venha ',
    nextStep: 'próximo passo',
    register: 'cadastro',
    setPassword: 'configurar senha',
    reg1: 'uma conta, aproveite',
    reg2: 'todos os serviços',
    sendCode: 'enviar código ',
    password: 'senha de login',
    repassword: 'confirmar senha de login',
    info: 'para segurança de seus ativos, guardem bem sua senha, chave e as informações sensíveis',
    agentcode1: 'código de recomendação(obrigatório)',
    agentcode2: 'código de recomendação(opcional )',
    agree1: 'lido e aceito ',
    agree2: 'o seguinte acordo',
    clause1: 'termos do usuário',
    clause2: 'política de privacidade ',
    resendcode: 'reenviar ',
    usertip: 'inserir número de celular/ email',
    teltip: 'inserir número de celular',
    emailtip: 'inserir email ',
    emailerr: 'forma de email incorreto, favor inserir novamente',
    confirmpwdtip: 'inserir confirmação de senha',
    confirmpwderr: 'as duas senha são inconsistentes',
    agentcodetip: 'inserir código de recomendação',
    singletip: 'assinar termo de usuário',
    pwdtip: 'inserir senha de login',
    telno: 'número de celular',
    smscode: '. Código por sms',
    smscodetip: 'inserir código sms',
    email: 'email',
    emailcode: 'código do email',
    emailcodetip: 'inserir código de email',
    tip: '. Avisos',
    memberNot: 'usuário não existente',
    phoneRegister: 'cadastro pelo celular',
    emailRegister: 'cadastro pelo email',
    registerSucceed: 'cadastro sucedido',
    membercode: 'código errado ',
    pwdvalidate: 'senha deve incluir pelo menos uma letra maiúscula, uma letra minúscula e número entre 6-20 dígitos',
    create: 'criar',
    chooseLoaction: 'favor selecionar região',
    user: 'número de celular/email',
    agentcode: 'código de convite (opcional)',
    goOn: 'prosseguir',
  },
  state: {
    Bhutan: 'Butão',
    EastSamoa: 'Samoa Oriental (USA)',
    China: 'China',
    Taiwan: 'Taiwan',
    TheCentralAfricanRepublic: 'República Centro - Africana',
    Denmark: 'Dinamarca',
    Ukraine: 'Ucrânia',
    Uzbekistan: 'Usbequistão ',
    Uganda: 'Uganda',
    Uruguay: 'Uruguai ',
    Chad: '. Chade ',
    Yemen: 'Lémen ',
    Armenia: 'Armênia',
    Israel: 'Israel',
    Iraq: 'Iraque',
    Iran: 'Irã',
    Belize: 'Belize',
    CapeVerde: 'Cabo Verde ',
    Russia: 'Rússia',
    Bulgaria: 'Bulgária ',
    Croatia: 'Croácia',
    Guam: 'Guam(USA)',
    Gambia: 'Gâmbia',
    Iceland: 'Islândia ',
    Guinea: 'Guiné',
    GuineaBissau: 'Guiné-Bissau',
    Liechtenstein: 'Liechtenstein',
    Congo: 'Congo',
    DemocraticRepublicoftheCongo: 'República Democrática do Congo',
    Libya: 'Líbia',
    Canada: 'Canadá',
    Ghana: 'Gana',
    Gabon: 'Gabão',
    Hungary: 'Hungria',
    SouthSudan: 'Sudão do Sul',
    SouthAfrica: 'África do Sul',
    Botswana: 'Botsuana',
    Qatar: 'Catar ',
    Rwanda: 'Ruanda',
    Luxembourg: 'Luxemburgo',
    India: 'Índia ',
    Indonesia: 'Indonésia',
    Guatemala: 'Guatemala',
    Ecuador: 'Equador ',
    Eritrea: 'Eritreia ',
    FormerDemocraticYemen: 'Ex-lêmen Democrata',
    Syria: 'Síria',
    Cuba: 'Cuba',
    Kyrgyzstan: 'Quirguistão',
    Djibouti: 'Djibuti',
    Kazakhstan: 'Cazaquistão',
    Colombia: 'Colômbia ',
    CostaRica: 'Costa Rica',
    Cameroon: 'Cameron',
    Tuvalu: 'Tuvalu ',
    Turkmenistan: 'Turquemenistão',
    Turkey: 'Turquia',
    SaintChristopherandNevis: 'São Cristóvão e Nevis ',
    SaintLucia: 'Santa Lúcia ',
    SaoTomeandPrincipe: 'São Tomé e Príncipe ',
    StVincent: 'São Vicente (Britânico)',
    StPierreandMiquelon: 'São Pedro e Miquelon',
    ChristmasIsland: 'Ilha do Natal',
    SanMarino: 'San Marino ',
    Guyana: 'Guiana',
    Tanzania: 'Tanzânia',
    Egypt: 'Egito',
    Ethiopia: 'Etiopia ',
    Kiribati: 'Kiribati',
    Tajikistan: 'Tajiquistão',
    Senegal: 'Senegal ',
    SierraLeone: 'Serra Leoa',
    Cyprus: 'Chipre',
    Seychelles: 'Seychelles',
    Mexico: 'México',
    Hawaii: 'Havaí',
    Togo: 'Togo',
    DominicanRepublic: 'República Dominicana ',
    DominicanFederation: 'Federação Dominicana',
    Austria: 'Áustria ',
    Venezuela: 'Venezuela',
    WakeIsland: 'Ilha Wake (USA)',
    Bangladesh: 'Bangladesh',
    Angola: 'Angola',
    Anguilla: 'Anguilla (Britânico)',
    AntiguaandBarbuda: 'Antígua e Barbuda',
    Andorra: 'Andorra',
    Micronesia: 'Micronésia',
    Nicaragua: 'Nicarágua',
    Nigeria: 'Nigéria',
    Niger: 'Níger',
    Nepal: 'Nepal',
    Bahamas: 'Bahamas',
    Pakistan: 'Paquistão',
    Barbados: 'Barbados ',
    PapuaNewGuinea: 'Papua Nova Guiné ',
    Paraguay: 'Paraguai',
    Panama: 'Panama ',
    Bahrain: 'Barém',
    Brazil: 'Brasil',
    BurkinaFaso: 'Burkina Faso',
    Burundi: 'Burundi ',
    Greece: 'Grécia ',
    Palau: 'Palau',
    CaymanIslands: 'Ilhas Cayman (Britânico)',
    Germany: ' Alemanha ',
    Italy: 'Itália',
    SolomonIslands: 'Ilhas Salomão (Britânico)',
    TokelauIslands: 'Tokelau (Novo)',
    Latvia: 'Letônia ',
    Norway: 'Noruega',
    CzechRepublic: 'República Checa',
    Moldova: 'Moldova',
    Morocco: 'Morrocos',
    Monaco: 'Mônaco',
    Brunei: 'Brunei ',
    Fiji: 'Fiji',
    Swaziland: 'Suazilândia',
    Slovakia: 'Eslováquia ',
    Slovenia: 'Eslovênia',
    SriLanka: 'Sri Lanka',
    Singapore: 'Singapura',
    NewCaledonia: 'Nova Caledônia (França)',
    newZealand: ' Nova Zelândia ',
    Japan: '. Japão ',
    Chile: 'Chile',
    Korea: 'Coréia',
    TheKingdomofCambodia: 'Reino do Camboja',
    Grenada: 'Granada',
    Greenland: 'Gronelândia ',
    Georgia: 'Geórgia ',
    Zanzibar: 'Zanzibar',
    Vatican: 'Vaticano ',
    Belgium: 'Bélgica',
    Mauritania: 'Mauritânia',
    Mauritius: 'Maurícia',
    Tonga: 'Tonga',
    SaudiArabia: 'Arábia Saudita',
    France: 'França',
    FrenchGuiana: 'Guiana Francesa',
    FrenchPolynesia: 'Polinésia Francesa',
    FaroeIslands: 'Ilhas Faroe',
    Poland: 'Polônia',
    Polynesia: 'Polinésia',
    PuertoRico: 'Porto Rico (USA)',
    BosniaandHerzegovina: 'Bósnia e Herzegovina',
    Thailand: 'Tailândia',
    Zimbabwe: 'Zimbábue',
    Honduras: 'Honduras',
    Haiti: 'Haiti',
    Australia: 'Austrália',
    Macao: 'Macau ',
    Ireland: 'Irlanda',
    Estnia: 'Estônia',
    Jamaica: 'Jamaica',
    TurksandCaicosIslands: 'Ilhas Turcas e Caicos ',
    TrinidadandTobago: 'Trindade e Tobago ',
    Bolivia: 'Bolivia ',
    Nauru: 'Nauru',
    Sweden: 'Suécia',
    Switzerland: 'Suíça',
    Guadeloupe: 'Guadalupe (França)',
    WallisandFujinaIslands: 'Ilhas Wallis e Fujina',
    Vanuatu: 'Vanuatu',
    Reunion: 'Ilha da Reunião(França)',
    Belarus: 'Bielorrússia',
    Bermuda: 'Bermudas (Britânico)',
    Gibraltar: 'Gibraltar (Britânico)',
    FalklandIslands: 'Ilhas Falkland',
    CorkIslands: 'Ilhas Cork (Novo)',
    Kuwait: 'Kuwait',
    Comoros: 'Comores',
    CotedIvoire: 'Costa do Marfim',
    CocosIsland: 'Ilha dos Cocos',
    Peru: 'Peru',
    Tunisia: 'Tunísia',
    Lithuania: 'Lituânia',
    Somalia: 'Somália ',
    Jordan: 'Jordânia',
    Namibia: 'Namíbia',
    Niue: 'Niue ( Novo)',
    TheVirginIslands: 'Ilhas Virgens (Britânico)',
    Myanmar: '. Myanmar',
    Romania: 'Romênia',
    USA: 'Estados Unidos',
    Laos: 'Laos',
    Kenya: 'Quênia',
    Finland: 'Finlândia ',
    Sudan: 'Sudão ',
    Suriname: 'Suriname ',
    UnitedKingdom: 'Reino Unido',
    Netherlands: 'Holanda',
    NetherlandsAntilles: 'Antilhas Holandesas ',
    Mozambique: 'Moçambique',
    Lesotho: 'Lesoto',
    Philippines: ' Filipinas',
    Salvador: 'Salvador',
    Portugal: 'Portugal',
    Mongolia: 'Mongólia ',
    Montserrat: 'Monstserrat',
    Spain: 'Espanha ',
    WesternSamoa: 'Samoa Ocidental ',
    NorfolkIsland: 'Ilha Norfolk',
    Benin: 'Benin',
    Zambia: 'Zâmbia ',
    EquatorialGuinea: 'Guiné Equatorial',
    Vietnam: 'Vietnã',
    DiegoGarcia: 'Diego Garcia ',
    Azerbaijan: 'Azerbaijão',
    Afghanistan: 'Afeganistão',
    Algeria: 'Argélia ',
    Albania: 'Albânia',
    UnitedArabEmirates: 'Emirados Árabes Unidos',
    Alaska: 'Alasca',
    Oman: 'Omã ',
    Argentina: 'Argentina ',
    Ascension: 'Ascensão  (Britânico)',
    Aruba: 'Aruba',
    SouthKorea: 'Coréia do sul',
    HongKong: 'Hong Kong',
    Macedonia: 'Macedônia',
    Maldives: 'Maldivas',
    Malvinas: 'Ilhas Malvinas ',
    Malawi: 'Malawi ',
    Martinique: 'Martinica (França)',
    Malaysia: 'Malásia ',
    Mayotte: 'Mayotte',
    MarshallIslands: 'Ilhas Marshall',
    Malta: 'Maltas ',
    Madagascar: 'Madagascar ',
    Mali: 'Mali',
    MarianaIslands: 'Ilhas Marianas ',
    Lebanon: 'Líbano',
  }
}