<template>
  <div class="operationalArea">
    <div v-if="isLogged">
      <Dropdown @on-click="goToAnother(0, $event)" class="userInfo" v-show="assetList.length>0">
        <span class="currentCountry">{{ $t('v.operation.asset') }}</span>
        <DropdownMenu slot="list">
          <div v-for="current in assetList" :name="current.name" :key="current.name">
            <p class="itemtit" v-if="current.name == 'MyAsset'">{{ $t('v.operation.spot_asset') }}</p>
            <p class="itemtit" v-else-if="current.name == 'Otc_Asset'">{{ $t('v.operation.wallet') }}</p>
            <p class="itemtit" v-else-if="current.name == 'Cfd_asset'">{{ $t('v.operation.cfd_account') }}</p>
            <Dropdown-item :name="current.name">
              <a href="javascript:void(0)">{{ $t(`v.operation.${current.name}`) }}</a>
            </Dropdown-item>
          </div>
        </DropdownMenu>
      </Dropdown>
      <Dropdown @on-click="goToAnother(1, $event)" class="userInfo" v-show="orderList.length>0" >
        <span class="currentCountry">{{ $t('v.operation.order') }}</span>
        <DropdownMenu slot="list">
          <div v-for="current in orderList" :name="current.name" :key="current.name">
            <p class="itemtit" v-if="current.name == 'Current_Order'">{{ $t('v.operation.exchange_order') }}</p>
            <p class="itemtit" v-else-if="current.name == 'In_Order'">{{ $t('v.operation.otcorder') }}</p>
            <Dropdown-item :name="current.name">
              <a href="javascript:void(0)">{{ $t(`v.operation.${current.name}`) }}</a>
            </Dropdown-item>
          </div>
        </DropdownMenu>
      </Dropdown>
      <Dropdown @on-click="goToAnother(2, $event)" class="userInfo" v-show="memberList.length>0">
        <Icon class="user" type="md-person" color="#828488" size="23" :style="iconStyle" />
        <DropdownMenu slot="list">
          <div v-for="current in memberList" :name="current.name" :key="current.name">
            <Dropdown-item :name="current.name">
              <a href="javascript:void(0)">{{ $t(`v.operation.${current.name}`) }}</a>
            </Dropdown-item>
          </div>
          <Divider />
          <DropdownItem name="Logout">
            <a href="javascript:void(0)">{{ $t('v.operation.logout') }}</a>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <div v-else>
      <a :href="commonInfo.cas.basePath" class="login">{{$t('v.operation.login')}}</a>
    </div>
    <Dropdown @on-click="changeLanguage">
      <span class="currentCountry">
        <img :src="countries.default.flag" alt="defaultFlag" />
      </span>
      <DropdownMenu slot="list">
        <DropdownItem
          class="languageDropdownItem"
          v-for="country in countries.list"
          :name="country.name"
          :key="country.name"
          :style="{display: countries.default.name === country.name ? 'none' : 'block'}"
        >
          <img :src="country.flag" :alt="country.name" />
          <span>{{ country.name }}</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
import ChineseFlag from '../../../../assets/images/Chinese-flag.svg'
import EnglishFlag from '../../../../assets/images/English-flag.svg'

export default {
  name: 'OperationalArea',
  props: {
    assetList: {
      required: true,
      type: Array
    },
    orderList: {
      required: true,
      type: Array
    },
    memberList: {
      required: true,
      type: Array
    },
    isLogged: Boolean
  },
  data () {
    return {
      countries: {
        default: { name: 'en-US', flag: ChineseFlag },
        list: [
          { name: 'zh-CN', flag: ChineseFlag },
          { name: 'en-US', flag: EnglishFlag }
        ]
      },
    }
  },
  computed: {
    iconStyle () {
      return {
        verticalAlign: 'middle',
        cursor: 'pointer'
      }
    },
    commonInfo () {
      return this.$store.state.__commonInfo
    },
    // eslint-disable-next-line vue/no-dupe-keys
    countries() {
      this.commonInfo.lang.forEach(element => {
        element.flag = require(`../../../../assets/images/${element.flag}.svg`)
      })
      return {
        default: this.commonInfo.lang[0],
        list: this.commonInfo.lang
      }
    }
  },
  methods: {
    changeLanguage (name) {
      // 选择后需缓存到本地
      this.countries.default = this.countries.list.find(item => {
        return item.name === name
      })
      this.$EventBus.emit('_languageSwitched', name)
    },
    goToAnother (key, name) {
      if (key === 2) {
        if (name === 'Logout') {
          this.$EventBus.emit('LOGOUT')
        } else {
          this.routeToOther(this.memberList, name)
        }
      } else {
        let list = key === 0 ? this.assetList : this.orderList
        this.routeToOther(list, name)
      }
    },
    routeToOther (list, name) {
      let index = list.findIndex(item => item.name === name)
      if (index > -1) {
        window.location.href = `${list[index].url}`
      }
    },
    setLang () {
      let defaultLang = window.localStorage.getItem('lang')
      if (defaultLang) {
        this.countries.default = this.countries.list.find(item => {
          return item.name === defaultLang
        })
      }
    }
  },
  created () {
    this.setLang()
  }
}
</script>

<style lang="less" scoped>
@import './operational-area';
</style>
