module.exports = {
  commonheader: {
    home: '首页',
    market: '行情'
  },
  commonfooter: {
    copyright: '版权所有',
    plat: '数字货币交易平台',
    download: '立即下载'
  },
  indexPage: {
    title: '您/身/边/最/值/得/信/赖/的',
    register: '注册',
    registerPlaceholder: '请输入您的邮箱或手机号',
    tradeSystem: {
      spot: {
        name: '币币交易',
        title: '全币种数字资产交换',
        t1: '纯内存高性能撮合',
        t2: '数百个交易对',
        t3: '极低的交易成本',
        t4: '极佳的流动性'
      },
      otc: {
        name: '法币交易',
        title: '支持与法币的兑换',
        t1: '零手续费',
        t2: '双向申诉渠道 保证双方利益',
        t3: '自选+快捷交易 双模式多方向',
        t4: '商家保证金制度 高安全性'
      },
      cfd: {
        name: '合约交易',
        title: '最简单人性化的合约设计',
        t1: '穿仓保护机制 降低交易风险',
        t2: '没有交割日期 摊平亏损',
        t3: '止盈、止损 多模式挂单',
        t4: '标记价格交易',
        t5: '防止恶意操作行情'
      }
    },
    product: {
      title: '产品与服务',
      desc: '为全球数百万用户提供安全、可信赖的数字资产交易及资产管理服务，助力区块链经济进化，重塑行业生态新格局',
      t1: '全球化布局',
      p1: '多个国家设立本土化交易服务中心打造多业务形态为一体',
      t2: '安全体系',
      p2: '专业分布式架构和防DOS攻击系统，冷热钱包分离，多维度保护用户资产安全',
      t3: '流畅交易体验',
      p3: '采用高效撮合交易引擎确保流畅交易体验，每秒可处理百万级订单，可支持同时在线用户数量超2000万的流畅稳定运行',
      t4: '专业团队护航',
      p4: '金融行业资深专家团队开发完成，精雕细琢每一个细节，保障交易平台的安全、稳定、高效运行'
    },
    download: {
      title: '随时随地 流畅交易',
      desc: '提供了一个直观的界面，带有实时订单、制图工具等，支持IOS、Android、Mac等多种平台的全服务功能',
      btn: '立即下载'
    },
    price: {
      th1: '交易对',
      th2: '最新价',
      th3: '24h涨幅',
      th4: '24h最高',
      th5: '24h最低',
      th6: '24h成交量',
      th7: '操作',
      buy: '购买'
    }
  },
  service: {
    USDT: 'USDT',
    BTC: 'BTC',
    ETH: 'ETH',
    CUSTOM: '自选',
    COIN: '币种',
    NewPrice: '最新价',
    ExchangeNum: '24H量',
    Change: '24H涨跌幅',
    high: '24H最高',
    low: '24H最低',
    OpenPrice: '开盘价',
    PriceTrend: '价格趋势',
    Operate: '操作',
    Exchange: '交 易'
  },
  register: {
    come: '就来',
    nextStep: '下一步',
    register: '注册',
    setPassword: '设置密码',
    reg1: '一个账户、享受',
    reg2: '所有服务',
    sendCode: '发送验证码',
    password: '登录密码',
    repassword: '确认登录密码',
    info: '为了您的财产安全、务必保管好您的密码、私钥等敏感信息。',
    agentcode1: '推荐码（必填）',
    agentcode2: '推荐码（选填）',
    agree1: ' 我已知晓并同意 ',
    agree2: '如下协议',
    clause1: '用户条款',
    clause2: '隐私政策',
    resendcode: '重新发送',
    usertip: '请输入手机号/邮箱',
    teltip: '请输入手机号',
    emailtip: '请输入邮箱',
    emailerr: '邮箱格式不正确,请重新输入',
    confirmpwdtip: '请输入确认密码',
    confirmpwderr: '两次密码输入不一致',
    agentcodetip: '请输入推荐码',
    singletip: '请勾选用户协议',
    pwdtip: '请输入登录密码',
    telno: '手机号',
    smscode: '短信验证码',
    smscodetip: '请输入短信验证码',
    email: '邮箱',
    emailcode: '邮箱验证码',
    emailcodetip: '请输入邮箱验证码',
    tip: '温馨提示',
    memberNot: '用户不存在',
    phoneRegister: '手机号注册',
    emailRegister: '邮箱注册',
    registerSucceed: '注册成功',
    membercode: '验证码错误',
    pwdvalidate: '密码至少包括一个大写字母、一个小写字母和数字6-20位',
    create: '创建',
    chooseLoaction: '请选择地区',
    user: '手机号/邮箱',
    agentcode: '邀请码（选填）',
    goOn: '继续'
  },
  state: {
    Bhutan: '不丹',
    EastSamoa: '东萨摩亚(美)',
    China: '中国',
    Taiwan: '中国台湾',
    TheCentralAfricanRepublic: '中非',
    Denmark: '丹麦',
    Ukraine: '乌克兰',
    Uzbekistan: '乌兹别克斯坦',
    Uganda: '乌干达',
    Uruguay: '乌拉圭',
    Chad: '乍得',
    Yemen: '也门',
    Armenia: '亚美尼亚',
    Israel: '以色列',
    Iraq: '伊拉克',
    Iran: '伊朗',
    Belize: '伯利兹',
    CapeVerde: '佛得角',
    Russia: '俄罗斯',
    Bulgaria: '保加利亚',
    Croatia: '克罗地亚',
    Guam: '关岛(美)',
    Gambia: '冈比亚',
    Iceland: '冰岛',
    Guinea: '几内亚',
    GuineaBissau: '几内亚比绍',
    Liechtenstein: '列支敦士登',
    Congo: '刚果',
    DemocraticRepublicoftheCongo: '刚果金',
    Libya: '利比亚',
    Canada: '加拿大',
    Ghana: '加纳',
    Gabon: '加蓬',
    Hungary: '匈牙利',
    SouthSudan: '南苏丹',
    SouthAfrica: '南非',
    Botswana: '博茨瓦纳',
    Qatar: '卡塔尔',
    Rwanda: '卢旺达',
    Luxembourg: '卢森堡',
    India: '印度',
    Indonesia: '印度尼西亚',
    Guatemala: '危地马拉',
    Ecuador: '厄瓜多尔',
    Eritrea: '厄立特里亚',
    FormerDemocraticYemen: '原民主也门地区',
    Syria: '叙利亚',
    Cuba: '古巴',
    Kyrgyzstan: '吉尔吉斯斯坦',
    Djibouti: '吉布提',
    Kazakhstan: '哈萨克斯坦',
    Colombia: '哥伦比亚',
    CostaRica: '哥斯达黎加',
    Cameroon: '喀麦隆',
    Tuvalu: '图瓦卢',
    Turkmenistan: '土库曼斯坦',
    Turkey: '土耳其',
    SaintChristopherandNevis: '圣克里斯托弗和尼维斯',
    SaintLucia: '圣卢西亚',
    SaoTomeandPrincipe: '圣多美和普林西比',
    StVincent: '圣文森特岛(英)',
    StPierreandMiquelon: '圣皮埃尔岛及密克隆岛',
    ChristmasIsland: '圣诞岛',
    SanMarino: '圣马力诺',
    Guyana: '圭亚那',
    Tanzania: '坦桑尼亚',
    Egypt: '埃及',
    Ethiopia: '埃塞俄比亚',
    Kiribati: '基里巴斯',
    Tajikistan: '塔吉克斯坦',
    Senegal: '塞内加尔',
    SierraLeone: '塞拉利昂',
    Cyprus: '塞浦路斯',
    Seychelles: '塞舌尔',
    Mexico: '墨西哥',
    Hawaii: '夏威夷',
    Togo: '多哥',
    DominicanRepublic: '多米尼加共和国',
    DominicanFederation: '多米尼加联邦',
    Austria: '奥地利',
    Venezuela: '委内瑞拉',
    WakeIsland: '威克岛(美)',
    Bangladesh: '孟加拉国',
    Angola: '安哥拉',
    Anguilla: '安圭拉岛(英)',
    AntiguaandBarbuda: '安提瓜和巴布达',
    Andorra: '安道尔',
    Micronesia: '密克罗尼西亚',
    Nicaragua: '尼加拉瓜',
    Nigeria: '尼日利亚',
    Niger: '尼日尔',
    Nepal: '尼泊尔',
    Bahamas: '巴哈马',
    Pakistan: '巴基斯坦',
    Barbados: '巴巴多斯',
    PapuaNewGuinea: '巴布亚新几内亚',
    Paraguay: '巴拉圭',
    Panama: '巴拿马',
    Bahrain: '巴林',
    Brazil: '巴西',
    BurkinaFaso: '布基纳法索',
    Burundi: '布隆迪',
    Greece: '希腊',
    Palau: '帕劳',
    CaymanIslands: '开曼群岛(英)',
    Germany: '德国',
    Italy: '意大利',
    SolomonIslands: '所罗门群岛(英)',
    TokelauIslands: '托克劳群岛(新)',
    Latvia: '拉脱维亚',
    Norway: '挪威',
    CzechRepublic: '捷克',
    Moldova: '摩尔多瓦',
    Morocco: '摩洛哥',
    Monaco: '摩纳哥',
    Brunei: '文莱',
    Fiji: '斐济',
    Swaziland: '斯威士兰',
    Slovakia: '斯洛伐克',
    Slovenia: '斯洛文尼亚',
    SriLanka: '斯里兰卡',
    Singapore: '新加坡',
    NewCaledonia: '新喀里多尼亚群岛(法)',
    newZealand: '新西兰',
    Japan: '日本',
    Chile: '智利',
    Korea: '朝鲜',
    TheKingdomofCambodia: '柬埔寨王国',
    Grenada: '格林纳达',
    Greenland: '格陵兰岛',
    Georgia: '格鲁吉亚',
    Zanzibar: '桑给巴尔',
    Vatican: '梵蒂冈',
    Belgium: '比利时',
    Mauritania: '毛里塔尼亚',
    Mauritius: '毛里求斯',
    Tonga: '汤加',
    SaudiArabia: '沙特阿拉伯',
    France: '法国',
    FrenchGuiana: '法属圭亚那',
    FrenchPolynesia: '法属波利尼西亚',
    FaroeIslands: '法罗群岛',
    Poland: '波兰',
    Polynesia: '波利尼西亚',
    PuertoRico: '波多黎各(美)',
    BosniaandHerzegovina: '波斯尼亚和黑塞哥维那',
    Thailand: '泰国',
    Zimbabwe: '津巴布韦',
    Honduras: '洪都拉斯',
    Haiti: '海地',
    Australia: '澳大利亚',
    Macao: '中国澳门',
    Ireland: '爱尔兰',
    Estnia: '爱沙尼亚',
    Jamaica: '牙买加',
    TurksandCaicosIslands: '特克斯和凯科斯群岛',
    TrinidadandTobago: '特立尼达和多巴哥',
    Bolivia: '玻利维亚',
    Nauru: '瑙鲁',
    Sweden: '瑞典',
    Switzerland: '瑞士',
    Guadeloupe: '瓜德罗普岛(法)',
    WallisandFujinaIslands: '瓦利斯和富士那群岛',
    Vanuatu: '瓦努阿图',
    Reunion: '留尼旺岛(法)',
    Belarus: '白俄罗斯',
    Bermuda: '百慕大群岛(英)',
    Gibraltar: '直布罗陀(英)',
    FalklandIslands: '福克兰群岛',
    CorkIslands: '科克群岛(新)',
    Kuwait: '科威特',
    Comoros: '科摩罗',
    CotedIvoire: '科特迪瓦',
    CocosIsland: '科科斯岛',
    Peru: '秘鲁',
    Tunisia: '突尼斯',
    Lithuania: '立陶宛',
    Somalia: '索马里',
    Jordan: '约旦',
    Namibia: '纳米比亚',
    Niue: '纽埃岛(新)',
    TheVirginIslands: '维尔京群岛(英)',
    Myanmar: '缅甸',
    Romania: '罗马尼亚',
    USA: '美国',
    Laos: '老挝',
    Kenya: '肯尼亚',
    Finland: '芬兰',
    Sudan: '苏丹',
    Suriname: '苏里南',
    UnitedKingdom: '英国',
    Netherlands: '荷兰',
    NetherlandsAntilles: '荷属安的列斯群岛',
    Mozambique: '莫桑比克',
    Lesotho: '莱索托',
    Philippines: '菲律宾',
    Salvador: '萨尔瓦多',
    Portugal: '葡萄牙',
    Mongolia: '蒙古',
    Montserrat: '蒙特塞拉特岛',
    Spain: '西班牙',
    WesternSamoa: '西萨摩亚',
    NorfolkIsland: '诺福克岛(澳)',
    Benin: '贝宁',
    Zambia: '赞比亚',
    EquatorialGuinea: '赤道几内亚',
    Vietnam: '越南',
    DiegoGarcia: '迪戈加西亚',
    Azerbaijan: '阿塞拜疆',
    Afghanistan: '阿富汗',
    Algeria: '阿尔及利亚',
    Albania: '阿尔巴尼亚',
    UnitedArabEmirates: '阿拉伯联合酋长国',
    Alaska: '阿拉斯加',
    Oman: '阿曼',
    Argentina: '阿根廷',
    Ascension: '阿森松(英)',
    Aruba: '阿鲁巴岛',
    SouthKorea: '韩国',
    HongKong: '中国香港',
    Macedonia: '马其顿',
    Maldives: '马尔代夫',
    Malvinas: '马尔维纳斯群岛',
    Malawi: '马拉维',
    Martinique: '马提尼克(法)',
    Malaysia: '马来西亚',
    Mayotte: '马约特岛',
    MarshallIslands: '马绍尔群岛',
    Malta: '马耳他',
    Madagascar: '马达加斯加',
    Mali: '马里',
    MarianaIslands: '马里亚纳群岛',
    Lebanon: '黎巴嫩'
  }
}