module.exports = {
  commonheader: {
    home: 'Home',
    market: 'Market'
  },
  commonfooter: {
    copyright: ' all rights reserved',
    plat: 'Digital Coin Trading Platform',
    download: 'Download Now'
  },
  indexPage: {
    title: 'The/most/trustworthy',
    register: 'Register',
    registerPlaceholder: 'Please enter your phone number or email',
    tradeSystem: {
      spot: {
        name: '币币交易',
        title: '全币种数字资产交换',
        t1: '纯内存高性能撮合',
        t2: '数百个交易对',
        t3: '极低的交易成本',
        t4: '极佳的流动性'
      },
      otc: {
        name: '法币交易',
        title: '支持与法币的兑换',
        t1: '零广告费',
        t2: '双向申诉渠道 保证双方利益',
        t3: '自选+快捷交易 双模式多方向',
        t4: '商家保证金制度 高安全性'
      },
      cfd: {
        name: '合约交易',
        title: '最简单人性化的合约设计',
        t1: '穿仓保护机制 降低交易风险',
        t2: '没有交割日期 摊平亏损',
        t3: '止盈、止损 多模式挂单',
        t4: '标记价格交易',
        t5: '防止恶意操作行情'
      }
    },
    product: {
      title: '产品与服务',
      desc: '为全球数百万用户提供安全、可信赖的数字资产交易及资产管理服务，助力区块链经济进化，重塑行业生态新格局',
      t1: '全球化布局',
      p1: '多个国家设立本土化交易服务中心打造多业务形态为一体',
      t2: '安全体系',
      p2: '专业分布式架构和防DOS攻击系统，冷热钱包分离，多维度保护用户资产安全',
      t3: '流畅交易体验',
      p3: '采用高效撮合交易引擎确保流畅交易体验，每秒可处理百万级订单，可支付同时在线用户数量超2000万的流畅稳定运行',
      t4: '专业团队护航',
      p4: '金融行业资深专家团队开发完成，精雕细琢每一个细节，保障交易平台的安全、稳定、高效运行'
    },
    download: {
      title: '随时随地 流畅交易',
      desc: '提供了一个直观的界面，带有实时订单、制图工具等，支出IOS、Android、Mac等多种平台的全服务功能',
      btn: '立即下载'
    },
    price: {
      th1: 'Pair',
      th2: 'Last Price',
      th3: '24H change',
      th4: '24H High',
      th5: '24H Low',
      th6: '24H Volume',
      th7: 'Operation',
      buy: 'Buy'
    }
  },
  service: {
    USDT: 'USDT ',
    BTC: 'BTC ',
    ETH: 'ETH ',
    CUSTOM: 'Custom Service',
    COIN: 'Coin',
    NewPrice: 'Lastest',
    ExchangeNum: 'Amount',
    Change: 'Change',
    high: 'Highest',
    low: 'Lowest',
    OpenPrice: 'Open Price',
    PriceTrend: 'Price Trend',
    Operate: 'Operate',
    Exchange: 'Exchange'
  },
  register: {
    come: 'Come to',
    register: 'Register',
    setPassword: 'Set Password',
    nextStep: 'Next Step',
    reg1: 'One account, all ',
    reg2: ' services',
    sendCode: 'Send code',
    password: 'Login password',
    repassword: 'Confirm password',
    info: 'For the security of your property, be sure to keep your password, private key and other sensitive information.',
    agentcode1: 'Referral Code (Essential)',
    agentcode2: 'Referral Code (Optional)',
    agree1: ' I agree ',
    agree2: ' agreement as follows',
    clause1: 'User terms',
    clause2: 'Privacy policy',
    resendcode: 'Resend',
    usertip: 'Please Enter phone/email',
    teltip: 'Enter tel',
    emailtip: 'Enter email',
    emailerr: 'Email format error,enter again',
    confirmpwdtip: 'Enter confirm password',
    confirmpwderr: 'Two passwords not same',
    agentcodetip: 'Please enter the recommendation code',
    singletip: 'Please check user agreement',
    pwdtip: 'Enter login password',
    telno: 'Tel',
    smscode: 'Sms verification code',
    smscodetip: 'Enter sms verification code',
    email: 'Email',
    emailcode: 'Email verification code',
    emailcodetip: 'Enter email verification code',
    tip: 'Tip',
    memberNot: 'Member does not exist',
    phoneRegister: 'Phone register',
    emailRegister: 'Email register',
    registerSucceed: 'Register succeed',
    membercode: 'Verification code error',
    pwdvalidate: 'Password too simple',
    create: 'Create',
    chooseLoaction: 'Please choose location',
    user: 'Phone/Email',
    agentcode: 'agent code (alternative)',
    goOn: 'continue',
    sure: 'confirm'
  },
  state: {
    Bhutan: 'Bhutan',
    EastSamoa: 'East Samoa',
    China: 'China',
    Taiwan: 'Taiwan',
    TheCentralAfricanRepublic: 'The Central African Republic',
    Denmark: 'Denmark',
    Ukraine: 'Ukraine',
    Uzbekistan: 'Uzbekistan',
    Uganda: 'Uganda',
    Uruguay: 'Uruguay',
    Chad: 'Chad',
    Yemen: 'Yemen',
    Armenia: 'Armenia',
    Israel: 'Israel',
    Iraq: 'Iraq',
    Iran: 'Iran',
    Belize: 'Belize',
    CapeVerde: 'Cape Verde',
    Russia: 'Russia',
    Bulgaria: 'Bulgaria',
    Croatia: 'Croatia',
    Guam: 'Guam',
    Gambia: 'Gambia',
    Iceland: 'Iceland',
    Guinea: 'Guinea',
    GuineaBissau: 'Guinea-Bissau',
    Liechtenstein: 'Liechtenstein',
    Congo: 'Congo',
    DemocraticRepublicoftheCongo: 'Democratic Republic of the Congo',
    Libya: 'Libya',
    Canada: 'Canada',
    Ghana: 'Ghana',
    Gabon: 'Gabon',
    Hungary: 'Hungary',
    SouthSudan: 'South Sudan',
    SouthAfrica: 'South Africa',
    Botswana: 'Botswana',
    Qatar: 'Qatar',
    Rwanda: 'Rwanda',
    Luxembourg: 'Luxembourg',
    India: 'India',
    Indonesia: 'Indonesia',
    Guatemala: 'Guatemala',
    Ecuador: 'Ecuador',
    Eritrea: 'Eritrea',
    FormerDemocraticYemen: 'Former Democratic Yemen',
    Syria: 'Syria',
    Cuba: 'Cuba',
    Kyrgyzstan: 'Kyrgyzstan',
    Djibouti: 'Djibouti',
    Kazakhstan: 'Kazakhstan',
    Colombia: 'Colombia',
    CostaRica: 'Costa Rica',
    Cameroon: 'Cameroon',
    Tuvalu: 'Tuvalu',
    Turkmenistan: 'Turkmenistan',
    Turkey: 'Turkey',
    SaintChristopherandNevis: 'Saint Christopher and Nevis',
    SaintLucia: 'Saint Lucia',
    SaoTomeandPrincipe: 'Sao Tome and Principe',
    StVincent: 'St. Vincent',
    StPierreandMiquelon: 'St. Pierre and Miquelon',
    ChristmasIsland: 'Christmas Island',
    SanMarino: 'San Marino',
    Guyana: 'Guyana',
    Tanzania: 'Tanzania',
    Egypt: 'Egypt',
    Ethiopia: 'Ethiopia',
    Kiribati: 'Kiribati',
    Tajikistan: 'Tajikistan',
    Senegal: 'Senegal',
    SierraLeone: 'Sierra Leone',
    Cyprus: 'Cyprus',
    Seychelles: 'Seychelles',
    Mexico: 'Mexico',
    Hawaii: 'Hawaii',
    Togo: 'Togo',
    DominicanRepublic: 'Dominican Republic',
    DominicanFederation: 'Dominican Federation',
    Austria: 'Austria',
    Venezuela: 'Venezuela',
    WakeIsland: 'Wake Island',
    Bangladesh: 'Bangladesh',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    AntiguaandBarbuda: 'Antigua and Barbuda',
    Andorra: 'Andorra',
    Micronesia: 'Micronesia',
    Nicaragua: 'Nicaragua',
    Nigeria: 'Nigeria',
    Niger: 'Niger',
    Nepal: 'Nepal',
    Bahamas: 'Bahamas',
    Pakistan: 'Pakistan',
    Barbados: 'Barbados',
    PapuaNewGuinea: 'Papua New Guinea',
    Paraguay: 'Paraguay',
    Panama: 'Panama',
    Bahrain: 'Bahrain',
    Brazil: 'Brazil',
    BurkinaFaso: 'Burkina Faso',
    Burundi: 'Burundi',
    Greece: 'Greece',
    Palau: 'Palau',
    CaymanIslands: 'Cayman Islands',
    Germany: 'Germany',
    Italy: 'Italy',
    SolomonIslands: 'Solomon Islands',
    TokelauIslands: 'Tokelau Islands',
    Latvia: 'Latvia',
    Norway: 'Norway',
    CzechRepublic: 'Czech Republic',
    Moldova: 'Moldova',
    Morocco: 'Morocco',
    Monaco: 'Monaco',
    Brunei: 'Brunei',
    Fiji: 'Fiji',
    Swaziland: 'Swaziland',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    SriLanka: 'Sri Lanka',
    Singapore: 'Singapore',
    NewCaledonia: 'New Caledonia',
    newZealand: 'new Zealand',
    Japan: 'Japan',
    Chile: 'Chile',
    Korea: 'Korea',
    TheKingdomofCambodia: 'The Kingdom of Cambodia',
    Grenada: 'Grenada',
    Greenland: 'Greenland',
    Georgia: 'Georgia',
    Zanzibar: 'Zanzibar',
    Vatican: 'Vatican',
    Belgium: 'Belgium',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauritius',
    Tonga: 'Tonga',
    SaudiArabia: 'Saudi Arabia',
    France: 'France',
    FrenchGuiana: 'French Guiana',
    FrenchPolynesia: 'French Polynesia',
    FaroeIslands: 'Faroe Islands',
    Poland: 'Poland',
    Polynesia: 'Polynesia',
    PuertoRico: 'Puerto Rico',
    BosniaandHerzegovina: 'Bosnia and Herzegovina',
    Thailand: 'Thailand',
    Zimbabwe: 'Zimbabwe',
    Honduras: 'Honduras',
    Haiti: 'Haiti',
    Australia: 'Australia',
    Macao: 'Macao',
    Ireland: 'Ireland',
    Estnia: 'Estônia',
    Jamaica: 'Jamaica',
    TurksandCaicosIslands: 'Turks and Caicos Islands',
    TrinidadandTobago: 'Trinidad and Tobago',
    Bolivia: 'Bolivia',
    Nauru: 'Nauru',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    Guadeloupe: 'Guadeloupe',
    WallisandFujinaIslands: 'Wallis and Fujina Islands',
    Vanuatu: 'Vanuatu',
    Reunion: 'Reunion',
    Belarus: 'Belarus',
    Bermuda: 'Bermuda',
    Gibraltar: 'Gibraltar',
    FalklandIslands: 'Falkland Islands',
    CorkIslands: 'Cork Islands',
    Kuwait: 'Kuwait',
    Comoros: 'Comoros',
    CotedIvoire: 'Cote d Ivoire',
    CocosIsland: 'Cocos Island',
    Peru: 'Peru',
    Tunisia: 'Tunisia',
    Lithuania: 'Lithuania',
    Somalia: 'Somalia',
    Jordan: 'Jordan',
    Namibia: 'Namibia',
    Niue: 'Niue',
    TheVirginIslands: 'The Virgin Islands',
    Myanmar: 'Myanmar',
    Romania: 'Romania',
    USA: 'America',
    Laos: 'Laos',
    Kenya: 'Kenya',
    Finland: 'Finland',
    Sudan: 'Sudan',
    Suriname: 'Suriname',
    UnitedKingdom: 'United Kingdom',
    Netherlands: 'Netherlands',
    NetherlandsAntilles: 'Netherlands Antilles',
    Mozambique: 'Mozambique',
    Lesotho: 'Lesotho',
    Philippines: 'Philippines',
    Salvador: 'Salvador',
    Portugal: 'Portugal',
    Mongolia: 'Mongolia',
    Montserrat: 'Montserrat',
    Spain: 'Spain',
    WesternSamoa: 'Western Samoa',
    NorfolkIsland: 'Norfolk Island',
    Benin: 'Benin',
    Zambia: 'Zambia',
    EquatorialGuinea: 'Equatorial Guinea',
    Vietnam: 'Vietnam',
    DiegoGarcia: 'Diego Garcia',
    Azerbaijan: 'Azerbaijan',
    Afghanistan: 'Afghanistan',
    Algeria: 'Algeria',
    Albania: 'Albania',
    UnitedArabEmirates: 'United Arab Emirates',
    Alaska: 'Alaska',
    Oman: 'Oman',
    Argentina: 'Argentina',
    Ascension: 'Ascension',
    Aruba: 'Aruba',
    SouthKorea: 'South Korea',
    HongKong: 'HongKong',
    Macedonia: 'Macedonia',
    Maldives: 'Maldives',
    Malvinas: 'Malvinas',
    Malawi: 'Malawi',
    Martinique: 'Martinique',
    Malaysia: 'Malaysia',
    Mayotte: 'Mayotte',
    MarshallIslands: 'Marshall Islands',
    Malta: 'Malta',
    Madagascar: 'Madagascar',
    Mali: 'Mali',
    MarianaIslands: 'Mariana Islands',
    Lebanon: 'Lebanon'
  }
}